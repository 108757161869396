import {
    IonAccordion, IonAccordionGroup, IonContent, IonItemDivider, IonLabel, IonItem, IonSegment,
    IonSegmentButton, IonCheckbox, IonTextarea, IonButton, IonRadio, IonRadioGroup, IonInput,
    IonSelect,
    IonSelectOption
} from "@ionic/react";
import "./ScheduleAppointmentComponent.scss";
import React, {useState, useContext, useEffect, useRef} from "react";
import { ApplicationContext } from "../../misc/ApplicationContext";
import AnalyticsService from "../../misc/AnalyticsService";
import {useLocation} from "react-router-dom";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {ApiAcuityAppointment, ApiClinician, ApiCountry} from "../../utils/ApiTypes";
import {ClinicianAppointment} from "../ClinicianAppointment/ClinicianAppointment";
import {ClinicianCalendar} from "../ClinicianCalendar/ClinicianCalendar";
import { format, differenceInHours} from "date-fns";
import {ScheduleAppointmentTerms} from "./ScheduleAppointmentTerms";
import { useTranslation } from 'react-i18next';
import {User} from "../../utils/ApiTypes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PleaseWait } from "../PleaseWait/PleaseWait";
import {
    isValidPhoneNumber,
    CountryCode,
    parsePhoneNumber
  } from 'libphonenumber-js';
import useCompanyBenefits from "../CustomHooks/useCompanyBenefits";

interface scheduleAppointmentComponentProps {
    currentUser: User,
    isComponentVisible: boolean,
    isWpo: boolean,
}

export const ScheduleAppointmentComponent: React.FC<scheduleAppointmentComponentProps> = ({currentUser, isComponentVisible, isWpo}) => {

    const {handleGeneralError, isMobileWidth, handleUserError, language, isMobileApp} = useContext(ApplicationContext);
    const location = useLocation();
    const [segmentValue, setSegmentValue] = useState<string>('clinician');
    const [clinicianSelected, setClinicianSelected] = useState<ApiClinician| null>(null);
    const [dateSelected, setDateSelected] = useState<Date | null>(null);
    const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
    const [stepsTracked, setStepsTracked] = useState<string[]>([]);
    const accordionGroup = useRef<HTMLIonAccordionGroupElement | null>(null);
    const [isContentLoading, setIsContentLoading] = useState<boolean>(true);
    const [meetingType, setMeetingType] = useState<string>("phone-meeting");
    const [phoneNumber, setPhoneNumber] = useState<string | null>(currentUser.phoneNumber ?? null);
    const [phoneCountry, setPhoneCountry] = useState<string>(language?.countryId ?? 'US');
    const [isMeetingConfirmed, setIsMeetingConfirmed] = useState<boolean>(false);
    const { defaultPhoneNumber } = useCompanyBenefits();
    
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        if(!isComponentVisible)return;
        setStepsTracked([]);
        setIsContentLoading(true);
        queryClient.invalidateQueries({ queryKey: ['getCliniciansV2'] });
        const contentRequests = [
            trackFormOpen(),
        ];
        Promise.all(contentRequests).then(() => {
            setIsContentLoading(false);
            toggleAccordion("STEP1");
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isComponentVisible])

    async function toggleAccordion(step: string){
        //Hacky solution to ensure that in first render we have the reference for the accordion and avoid race condition
        do{
            await new Promise(r => setTimeout(r, 10));
        } while(!accordionGroup)
        if(accordionGroup){
            if(accordionGroup.current){
                accordionGroup.current.value = step;
                //Given format is 'STEP#', we are extracting the number
                let stepNumber: string = step.split("STEP")[1];
                if(!stepsTracked.includes(stepNumber)){
                    //We are tracking the step only if it is not already tracked to avoid duplicates
                    trackScheduleAppointmentSteps(stepNumber);
                    setStepsTracked([...stepsTracked, step]);
                }
            }
        }
    }

    async function fetchCountries(): Promise<ApiCountry[] | undefined> {
        try{
            return await JourneyApiClient.getCompanyCountries('all');
        } catch (e) {
            handleGeneralError("Could not fetch countries", e);
        }
    }
    const countriesQuery = useQuery<Promise<ApiCountry[] | undefined>, unknown, ApiCountry[]>
    (["fetchCountries"], fetchCountries);

    async function getAppointment(): Promise<ApiAcuityAppointment | undefined> {
        try{
            if(isWpo) return await JourneyApiClient.getScheduledWpoAppointment();
            else return await JourneyApiClient.getScheduledAppointment(new Date().toISOString());
        } catch (e) {
            handleGeneralError("Could not fetch appointment", e);
        }
    }
    const appointmentsQuery = useQuery<Promise<ApiAcuityAppointment | undefined>, unknown, ApiAcuityAppointment>
    (["getScheduledAppointment"], getAppointment);

    const calculateProximityToCurrentTime = (slot: string) => {
        const date = new Date(slot); // Parse the ISO string
        const slotHours = date.getHours();
        const slotMinutes = date.getMinutes();
        const totalSlotMinutes = slotHours * 60 + slotMinutes;
    
        // Get the user's current time (hours and minutes only)
        const now = new Date();
        const currentHours = now.getHours();
        const currentMinutes = now.getMinutes();
        const currentTimeInMinutes = currentHours * 60 + currentMinutes;
    
        return Math.abs(totalSlotMinutes - currentTimeInMinutes);
    };

    async function getClinicians(): Promise<ApiClinician[] | undefined> {
        try{
            let cliniciansList;
            const now: string | null = !currentUser || currentUser.company.type === "trial" ?
                new Date().toISOString() : null
            if(isWpo) cliniciansList = await JourneyApiClient.getWpoClinicians('Etc/GMT');
            else cliniciansList = await JourneyApiClient.getCliniciansV2(now);

            // sort the time slots
            // Map over clinicians and sort their slots based on proximity to noon
            return cliniciansList.map((clinician: ApiClinician) => ({
                ...clinician,
                slots: clinician.slots.sort((a, b) => calculateProximityToCurrentTime(a) - calculateProximityToCurrentTime(b)),
            }));
        } catch (e){
            handleGeneralError("Could not get clinicians", e);
        }
    }
    const clinicianQuery = useQuery<Promise<ApiClinician[] | undefined>, unknown, ApiClinician[]>
    (["getCliniciansV2"], getClinicians);

    function getAmountOfAppointmentsAvailable(): number | null {
        let clinicians = clinicianQuery.data;
        if(!clinicians) return null;
        const totalAppointmentsAvailable = clinicians.reduce((total, clinician) => {
            const clinicianSlots = clinician.slots.length;
            return total + clinicianSlots;
        }, 0);

        return totalAppointmentsAvailable;
    }

    async function trackFormOpen () {
        await AnalyticsService.trackUserAction("schedule_appointment_open", location.pathname);
    }

    async function trackFormSubmission (clinicianId: number, date: string) {
        let properties = {
            clinicianId: clinicianId,
            appointmentMonth: format(new Date(date), 'LLLL'),
            appointmentDayOfWeek: format(new Date(date), 'EEEE'),
            appointmentDayOfMonth: format(new Date(date), 'd'),
            appointmentLocalisedTime: format(new Date(date), 'p'),
            nowMonth: format(new Date(), 'LLLL'),
            nowDayOfWeek: format(new Date(), 'EEEE'),
            nowDayOfMonth: format(new Date(), 'd'),
            nowLocalisedTime: format(new Date(), 'p'),
            nowLocalisedYear: format(new Date(), 'y'),
            numberOfAppointmentsAvailableNow: getAmountOfAppointmentsAvailable(),
            supplier: isWpo ? "WPO" : "Acuity",
        }
        await AnalyticsService.trackUserAction("schedule_appointment_submission", location.pathname, properties);
    }

    async function trackRescheduleSubmission(clinicianId: number, date: string){
        let properties = {
            clinicianId: clinicianId,
            appointmentMonth: format(new Date(date), 'LLLL'),
            appointmentDayOfWeek: format(new Date(date), 'EEEE'),
            appointmentDayOfMonth: format(new Date(date), 'd'),
            appointmentLocalisedTime: format(new Date(date), 'p'),
            nowMonth: format(new Date(), 'LLLL'),
            nowDayOfWeek: format(new Date(), 'EEEE'),
            nowDayOfMonth: format(new Date(), 'd'),
            nowLocalisedTime: format(new Date(), 'p'),
            nowLocalisedYear: format(new Date(), 'y'),
            numberOfAppointmentsAvailableNow: getAmountOfAppointmentsAvailable(),
            supplier: isWpo ? "WPO" : "Acuity",
        }
        await AnalyticsService.trackUserAction("reschedule_appointment_submission", location.pathname, properties);
    }

    async function trackScheduleAppointmentSteps(step: string){
        await AnalyticsService.trackUserAction(`schedule_appointment_step_${step}`, location.pathname);
    }

    function handleSegmentButtonPressed(segmentButtonValue: string){
        setSegmentValue(segmentButtonValue);
    }

    function handleAppointmentClick(date: Date, clinician: ApiClinician){
        setClinicianSelected(clinician);
        setDateSelected(date);
        toggleAccordion("STEP2");
    }

    const appointmentMutation = useMutation({
        mutationFn: ({clinicianId, date}: { clinicianId: number, date: string, isReschedule: boolean}) => {
            if(isWpo) return JourneyApiClient.createWpoClinicianAppointment(clinicianId.toString(), format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss"), timeZone ?? format(new Date(date), "OOOO"), meetingType, sanitizePhoneNumber(phoneNumber))
            else return JourneyApiClient.createClinicianAppointment(clinicianId, date)
        }, onSuccess: async (data, {clinicianId, date, isReschedule}) => {
            toggleAccordion("STEP3");
            setIsMeetingConfirmed(true);
            if(isReschedule){trackRescheduleSubmission(clinicianId, date)}
            else{ trackFormSubmission(clinicianId, date)}
        }
    })

    async function handleAppointmentConfirmation(){
        if(dateSelected && clinicianSelected){
            if(currentUser && currentUser.company.type === "trial"){
                handleUserError(t("Scheduling an appointment is not available for Trial users."), " ");
            } else {
                try{
                    let isReschedule: boolean = false;
                    //Checking if there is an appointment already scheduled, therefore we are rescheduling
                    if(appointmentsQuery.status === "success" && appointmentsQuery.data){
                        if(isNaN(appointmentsQuery.data.appointmentId)) return; 
                        isReschedule = true; 
                        //If there is an appointment already scheduled, we need to cancel it first, only if the appointment is not within 24 hours
                        if(differenceInHours(new Date(appointmentsQuery.data.appointmentDatetime), new Date()) > 24){
                            await handleAppointmentCancel(appointmentsQuery.data.appointmentId);
                        } else{
                            handleUserError(t("We're sorry, you cannot reschedule an appointment within 24 hours of the appointment start time."), " ");
                            return;
                        }
                    }
                    await appointmentMutation.mutateAsync({clinicianId: clinicianSelected.id, date: dateSelected.toISOString(), isReschedule: isReschedule});
                } catch (e: any) {
                    //We are checking if the error message is along "The time "2024-01-10T18:00:00.000Z" is not far enough in advance."
                    const wordsToCheck = ["time", "not", "far", "enough", "advance"];
                    let errorDescription = "Schedule Appointment failed";
                    let errorHeader;
                    if(wordsToCheck.every(word => e.message.toLowerCase().includes(word))){
                        errorDescription = "We're sorry, you cannot schedule an appointment within 12 hours of the appointment start time.";
                        errorHeader = "Reschedule for Later";
                    } 
                    handleGeneralError(errorDescription, e, errorHeader, { 
                        contextName: "Clinician Appointment", 
                        contextData: {
                            dateSelected: dateSelected, 
                            clinicianId: clinicianSelected.id, 
                            calendarId: clinicianSelected.calendarId, 
                            userId: currentUser.id, 
                            acuityError: e,
                            language: language
                        } 
                    });
                }
            }
        }
    }

    const cancelAppointmentMutation = useMutation({
        mutationFn: async ({appointmentId}: { appointmentId: number}) => {
            if(isWpo) return await JourneyApiClient.cancelWpoAppointment(appointmentId.toString());
            else return await JourneyApiClient.cancelAppointment(appointmentId);
        }, onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getScheduledAppointment'] })
        }
    })

    async function handleAppointmentCancel(appointmentId: number){
        //We are not calling the analytics since the only reason we are cancelling is to reschedule and we are sending reschedule analytics
        try{
            await cancelAppointmentMutation.mutateAsync({appointmentId: appointmentId});
        } catch (e) {
            handleGeneralError("Could not cancel appointment", e);
        }
    }

    async function changeMeetingType(e: CustomEvent){
        setMeetingType(e.detail.value);
    }

    async function changePhoneNumber(e: CustomEvent){
        setPhoneNumber(e.detail.value!);
        e.stopPropagation();
    }

    async function changePhoneCountry(e: CustomEvent){
        setPhoneCountry(e.detail.value!);
        e.stopPropagation();
    }

    const validatePhoneNumber = () => {
        return isValidPhoneNumber(phoneNumber ?? '', phoneCountry as CountryCode);
    }

    const sanitizePhoneNumber = (phone: string | null): string | null => {
        if(!phone) return null;

        const parsedPhoneNumber = parsePhoneNumber(phone, phoneCountry as CountryCode);
        return parsedPhoneNumber.format("E.164");
    }

    const timezoneCode = new Date().toLocaleString('en', {timeZoneName: 'short'}).split(' ').pop();
    const appointmentConfirmationDisabled = !dateSelected || !isTermsChecked || (isWpo && !meetingType) || (isWpo && meetingType === "phone-meeting" && !validatePhoneNumber()) || appointmentMutation.isLoading || cancelAppointmentMutation.isLoading;

    return(
        <IonContent className="schedule-appointment-component">
            {isContentLoading ? <PleaseWait/> :
            <div className="schedule-appointment-container">
                <div className="top-container">
                    <div className="header-4">
                        {appointmentsQuery.status === "success" && appointmentsQuery.data 
                        ? t("Reschedule Appointment") : t("Schedule Appointment")}
                    </div>
                    <div className={`schedule-appointment-body ${appointmentsQuery.status === "success"  && appointmentsQuery.data && "pop-outin"} body-medium`}>
                        {appointmentsQuery.status === "success" && appointmentsQuery.data 
                        ? t("You can reschedule up to 24 hours before your appointment time. Your information is confidential. Availability shown in your time zone ") : t("Find some time with us. Your information is confidential. Availabilty shown in your time zone")} ({timezoneCode})
                    </div>
                    <div className={`schedule-appointment-body body-medium`}>
                        {`${t("Don’t see a time that works for you? Call us anytime at")} ${defaultPhoneNumber?.subtitle}`}
                    </div>
                </div>
                <div className="bottom-container">
                    <IonAccordionGroup className="accordion-group" ref={accordionGroup}>
                        <IonAccordion className="accordion" value={"STEP1"}>
                            <div className={"accordion-title overline"} slot={"header"}>{t("STEP 1. CHOOSE APPOINTMENT")}</div>
                            {isWpo && clinicianQuery.isFetching &&
                                <div className="gradient-text h6-bold" slot={"content"}>
                                    {t("Thank you for your patience! We’re loading the available appointments for you. This will only take about 15 seconds.")}
                                </div>
                            }
                            {!clinicianQuery.isFetching && clinicianQuery.status === "success" &&
                                <div className="segment-container" slot={"content"}>
                                    <IonSegment className={"segment"} value={segmentValue}>
                                        <IonSegmentButton
                                            className={`segment-button button-small-variant ${segmentValue === "clinician" ? "selected" : ""}`}
                                            value={"clinician"}
                                            onClick={() => handleSegmentButtonPressed("clinician")}>
                                            {t("By Clinician")}
                                        </IonSegmentButton>
                                        <IonSegmentButton
                                            className={`segment-button button-small-variant ${segmentValue === "availability" ? "selected" : ""}`}
                                            value={"availability"}
                                            onClick={() => handleSegmentButtonPressed("availability")}>
                                            {t("By Availability")}
                                        </IonSegmentButton>
                                    </IonSegment>
                                </div>
                            }
                            {segmentValue === "clinician" && !clinicianQuery.isFetching && clinicianQuery.status === "success" && clinicianQuery.data.map((clinician: ApiClinician, index: number) => {
                                return(
                                    <div key={index} slot={"content"}>
                                        {index > 0 &&
                                        <div  className="schedule-appointment-line-divider-container">
                                            <IonItemDivider className="schedule-appointment-line-divider"/>
                                        </div>}
                                        <div className="clinician-appointment-wrapper">
                                            <ClinicianAppointment isMobileWidth={isMobileWidth} clinician={clinician} onAppointmentClick={handleAppointmentClick}/>
                                        </div>
                                    </div>
                                )
                            })}
                            {segmentValue === "availability" && !clinicianQuery.isFetching && clinicianQuery.status === "success" && clinicianQuery.data.length > 0  &&
                                <div className={"clinician-calendar-wrapper"} slot={"content"}>
                                    <ClinicianCalendar clinicians={clinicianQuery.data} onAppointmentClick={handleAppointmentClick}/>
                                </div>
                            }
                            {!clinicianQuery.isFetching && clinicianQuery.status === "success" && clinicianQuery.data.length > 0 &&
                                <div slot={"content"}>
                                    <div  className="schedule-appointment-line-divider-container">
                                        <IonItemDivider className="schedule-appointment-line-divider"/>
                                    </div>
                                    <div className={`accordion-notice subtitle1`}>
                                        {`${t("Don’t see a time that works for you? Call us anytime at")} ${defaultPhoneNumber?.subtitle}`}
                                    </div>
                                </div>
                            }
                        </IonAccordion>
                        <IonAccordion className="accordion" value={"STEP2"} disabled={!clinicianSelected}>
                            <div className={"accordion-title overline"} slot={"header"}>{t("STEP 2. CONFIRM APPOINTMENT")}</div>
                            {isWpo && appointmentMutation.isLoading &&
                                <div className="gradient-text h6-bold" slot={"content"}>
                                    {t("Thank you for your patience! We’re booking the appointment for you. This will only take about 15 seconds.")}
                                </div>
                            }
                            {!appointmentMutation.isLoading &&
                            <div slot={"content"}>
                                <div className={"step-2-container"}>
                                    <div className={"clinician-biography-step-2"}>
                                        <div className={`clinician-image-container ${isMobileWidth ? "hide" : ""}`}>
                                            {clinicianSelected && clinicianSelected.thumbnailUrl && <img className={"clinician-image"} alt="Clinician thumbnail" src={clinicianSelected.thumbnailUrl}/>}
                                        </div>
                                        <div className={"clinician-biography-step-2-text-container"}>
                                            <div className={`clinician-name ${isMobileWidth ? "header-6-variant" : "header-5"}`}>
                                                {clinicianSelected && `${clinicianSelected.firstName} ${clinicianSelected.lastName} ${isWpo ? t('Phone Call') : t('Virtual Appointment')}`}
                                            </div>
                                            <div className={`clinician-meeting-duration ${isMobileWidth ? "header-6-variant" : "header-5"}`}>{`${isWpo ? '30' : '50'} ${t("minutes")}`}</div>
                                            <div className={`clinician-time ${isMobileWidth ? "header-6-variant" : "header-5"}`}>
                                                {dateSelected && `${format(dateSelected, 'EE, MMMM d')} at ${format(dateSelected, 'p')}`}
                                            </div>
                                        </div>
                                    </div>
                                    {isWpo && 
                                    <div className={"user-contact"}>
                                        <div className={`user-contact-title ${isMobileWidth ? "header-6-variant" : "header-5"}`}>
                                            {t("How would you like to meet?")}
                                        </div>
                                        <IonRadioGroup value={meetingType} onIonChange={changeMeetingType}>
                                            <div className="row">
                                                <IonRadio mode="md" value="phone-meeting" />
                                                <IonLabel className={"body-large meeting-label"}>{t("Clinician calls me")}</IonLabel>
                                            </div>
                                            <div className="indented-row">
                                                {meetingType === "phone-meeting" && countriesQuery.isSuccess &&
                                                    <>
                                                        <IonSelect
                                                            interface={isMobileApp ? "alert" : "popover"}
                                                            placeholder={`${t('Country')}*`}
                                                            onIonChange={changePhoneCountry}
                                                            className="ion-select button-medium"
                                                            value={phoneCountry}>
                                                            {countriesQuery.data.map((country) => {
                                                                    return <IonSelectOption value={country.id} key={country.id} className="foo">
                                                                        {t(country.name)}
                                                                    </IonSelectOption>
                                                            })}
                                                        </IonSelect>
                                                        <IonItem className={"phone-input"}>
                                                            <IonInput className={"button-medium"} placeholder={t("Enter your number") ?? ""} type="tel" autocomplete="tel" inputmode="tel" value={phoneNumber} onIonChange={changePhoneNumber} />
                                                        </IonItem>
                                                    </>
                                                }
                                            </div>
                                            {/* <div className="row">
                                                <IonRadio mode="md" value="video-meeting" />
                                                <IonLabel className={"body-large meeting-label"}>{t("Meet over video")}</IonLabel>
                                            </div>
                                            <div className="indented-row">
                                                {meetingType === "video-meeting" &&
                                                    <IonLabel className={"label caption video-notice-label"}>{t("Video link will be provided")}</IonLabel>
                                                }
                                            </div> */}
                                        </IonRadioGroup>
                                    </div>
                                    }
                                    <div className={"terms-container"}>
                                        <div className={"title subtitle1"}>
                                            {t("Notice of Privacy Practices")}
                                        </div>
                                        <IonItem className={"text-area-item"}>
                                            <IonTextarea className={"terms-text-area body-small"} rows={10} value={ScheduleAppointmentTerms} readonly={true}/>
                                        </IonItem>
                                        <IonItem className={"checkbox-item"}>
                                            <IonCheckbox className={"checkbox"} onIonChange={(e) => setIsTermsChecked(e.detail.checked)} slot={"start"}/>
                                            <IonLabel className={"label caption"}>{`${t("I have read and agree to the terms above.")} *`}</IonLabel>
                                        </IonItem>
                                        <div className={"step-2-button-container"}>
                                            <IonButton
                                                disabled={appointmentConfirmationDisabled}
                                                className={"step-2-button button-medium"}
                                                onClick={handleAppointmentConfirmation}>
                                                {t("Confirm Appointment")}
                                            </IonButton>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            }
                        </IonAccordion>
                        <IonAccordion className="accordion" value={"STEP3"} disabled={!isMeetingConfirmed}>
                            <div className={"accordion-title overline"} slot={"header"}>{t("STEP 3. CONFIRMATION RECEIPT")}</div>
                            <div slot={"content"}>
                                <div  className={"step-3-container"}>
                                    <div className={`appointment-confirmation-text ${isMobileWidth ? "subtitle1" : "header-5"}`}>
                                        {t("Your appointment is confirmed:")}
                                    </div>
                                    <div className={"clinician-biography-step-3-text-container"}>
                                        <div className={`clinician-name ${isMobileWidth ? "header-6-variant" : "header-5-variant"}`}>
                                            {clinicianSelected && `${clinicianSelected.firstName} ${clinicianSelected.lastName} ${isWpo ? t('Phone Call') : t('Virtual Appointment')}`}
                                        </div>
                                        <div className={`clinician-meeting-duration ${isMobileWidth ? "header-6-variant" : "header-5-variant"}`}>{`${isWpo ? '30' : '50'} ${t("minutes")}`}</div>
                                        <div className={`clinician-time ${isMobileWidth ? "header-6-variant" : "header-5-variant"}`}>
                                            {dateSelected && `${format(dateSelected, 'EE, MMMM d')} at ${format(dateSelected, 'p')}`}
                                        </div>
                                    </div>
                                    <div className={"add-it-to-calendar-container"}>
                                    </div>
                                </div>
                            </div>
                        </IonAccordion>
                    </IonAccordionGroup>
                </div>
            </div>}
        </IonContent>
    )
}